/**
 * List of allowed actions
 */
export enum ActionMemoryContextAction {
  OffHire = 'off-hire',
  ContractReference = 'contract-reference',
  UserInfoPatch = 'user-info-patch',
  UserEmailPatch = 'user-email-patch',
  UserTableConfig = 'user-table-config',
}

export interface ActionMemoryContainerItemBase<T_NEW = unknown, T_OLD = unknown> {
  id?: string; // uuid
  contextLevel: 'user' | 'debtor';
  contextId: string;
  contextAction: ActionMemoryContextAction;
  valueNew: T_NEW;
  valueOld?: T_OLD;
  reason?: string;
}

interface ActionMemoryContainerItemEditRequired<T_NEW = unknown, T_OLD = unknown>
  extends ActionMemoryContainerItemBase<T_NEW, T_OLD> {
  id: string;
}

interface ActionMemoryContainerItemRequiredFields {
  sub: string; // uuid
  partition: string;
}

interface ActionMemoryContainerItemCosmosDb {
  ttl?: number;
  ttlExpiry?: string; // NON FUNCTIONAL date representation
}

export type ActionMemoryContainerItem<T_NEW = unknown, T_OLD = unknown> = ActionMemoryContainerItemBase<T_NEW, T_OLD> &
  ActionMemoryContainerItemRequiredFields &
  ActionMemoryContainerItemCosmosDb;

type ActionMemoryFilterItem<T_NEW = unknown, T_OLD = unknown> = Partial<
  ActionMemoryContainerItemBase<T_NEW, T_OLD> & ActionMemoryContainerItemRequiredFields
>;

export interface ActionMemoryFilter<T_NEW = unknown, T_OLD = unknown> {
  items?: Array<ActionMemoryFilterItem<T_NEW, T_OLD>>;
}
